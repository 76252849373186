<template>
  <v-container class="p-3 mt-5">
    <loading-flux :value="loading"></loading-flux>
    <section class="d-flex flex-column flex-sm-row justify-content-between">
      <h2 class="ml-1 my-2">Solicitudes de compra</h2>
      <v-breadcrumbs :items="breadcrumb"></v-breadcrumbs>
    </section>
    <v-row align-content="start" align-md="center" class="mt-1 mb-5">
      <v-col cols="12" md="4" sm="12" class="pb-0">
        <filter-project
          label="Filtrar por proyecto"
          :projects="
            projects.items
              ? projects.items.filter((item) => item.warehouse)
              : []
          "
          :getProjectsFunction="getProjects"
          @cleanProject="cleanCurrentProject"
          @inputProject="
            updateCurrentProject($event);
            getPurchaseRequestsData(filters.page);
          "
        ></filter-project>
      </v-col>
      <v-col cols="12" md="4" sm="12" class="pb-0">
        <v-select
          v-model="filters.status"
          :items="
            Object.entries(MAP_STATUS_PURCHASE_REQUESTS).map(
              ([value, { name }]) => ({
                value,
                name,
              })
            )
          "
          @input="getPurchaseRequestsData(filters.page)"
          item-text="name"
          item-value="value"
          label="Filtrar por estatus"
          clearable
        >
        </v-select>
      </v-col>
      <v-col cols="12" md="4" sm="12" class="pb-0">
        <v-text-field
          v-model="filters.filter"
          @input="getPurchaseRequestsData(1)"
          clearable
          placeholder="Buscar:"
        ></v-text-field>
      </v-col>
    </v-row>
    <div
      class="d-flex align-items-center justify-content-end mb-2 flex-column flex-sm-row mb-1"
    >
      <h5 class="my-0">
        Total:
        {{ purchaseRequests.total }}
      </h5>
    </div>
    <v-data-table
      class="px-1 elevation-3"
      :headers="tableHeaders"
      :items="wrappedPurchaseRequests"
      :items-per-page="10"
      :page.sync="filters.page"
      hide-default-footer
      :loading="loadingPurchaseRequest"
      loading-text="Cargando solicitudes de compra"
      no-data-text="Sin solicitudes de compra"
    >
      <template v-slot:item.project="{ item }">
        <p class="text-left my-0">
          {{ item.project ? "Proyecto" : "CECO" }}
        </p>
      </template>
      <template v-slot:item.code="{ item }">
        <p class="text-left my-0">
          {{ item.project ? item.project.code : item.cost_center.code }}
        </p>
      </template>
      <template v-slot:item.sap_code="{ item }">
        <p class="text-left my-0">
          {{ item.sap_code }}
        </p>
      </template>
      <template v-slot:item.stage_code="{ item }">
        <p class="my-0">
          {{ item.stage_code ? item.stage_code : "-" }}
        </p>
      </template>
      <template v-slot:item.status="{ item }">
        <v-edit-dialog>
          <div class="d-flex align-items-center justify-content-start">
            <v-chip
              label
              small
              :color="getColorFromStatusPlataform(item.status_plataforma)"
              class="text-white"
            >
              {{ item.status_plataforma }}
            </v-chip>
          </div>
          <template v-slot:input> </template>
        </v-edit-dialog>
        <!-- <div class="d-flex align-items-center justify-content-start">
          <v-chip
            label
            small
            :color="
              getMappedStatusPurchaseRequest(
                item.status_manual ? item.status_manual : item.status
              ).color
            "
            class="text-white"
          >
            {{
              getMappedStatusPurchaseRequest(
                item.status_manual ? item.status_manual : item.status
              ).name
            }}
          </v-chip>
        </div> -->
      </template>
      <template v-slot:item.created_at="{ item }">
        <span class="mt-2" style="font-size: 12px">
          {{ new Date(item.created_at).toLocaleDateString() }}
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex align-items-center justify-content-center">
          <!-- <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="openCurrentEditDialog(item)"
                icon
                class="mx-2"
                color="warning"
                v-bind="attrs"
                v-on="on"
                x-small
              >
                <v-icon small>
                  mdi-pencil
                </v-icon>
              </v-btn>
            </template>
            <span>Modificar estatus de la solicitud</span>
          </v-tooltip> -->
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="openModalMaterialsPurchaseRequest(item)"
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                x-small
              >
                <v-icon> mdi-view-list-outline </v-icon>
              </v-btn>
            </template>
            <span>Ver materiales de la solicitud</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <div class="my-3 float-right">
      <v-pagination
        v-if="purchaseRequests.number_of_pages"
        total-visible="8"
        v-model="filters.page"
        :length="
          purchaseRequests.number_of_pages
            ? purchaseRequests.number_of_pages
            : 1
        "
      ></v-pagination>
    </div>
    <modal-materials-purchase-request
      :modalAction="dialogMaterialsPurchaseRequest"
      :closeModalMaterialsPurchaseRequest="closeModalMaterialsPurchaseRequest"
      :currentPurchaseRequest="currentPurchaseRequest"
    >
    </modal-materials-purchase-request>
  </v-container>
</template>

<script>
import moment from "moment";
import { authUsuarioComputed } from "@/state/helpers";
import FilterProject from "@/components/commons/projects/FilterProject.vue";
import { mapActions, mapGetters } from "vuex";
import { MAP_STATUS_PURCHASE_REQUESTS } from "@/constants/supplying/purchaseRequests";
import ModalMaterialsPurchaseRequest from "./ModalMaterialsPurchaseRequest.vue";
import swal2 from "sweetalert2";

export default {
  components: {
    FilterProject,
    ModalMaterialsPurchaseRequest,
  },
  mounted() {
    this.getPurchaseRequestsData();
  },
  data() {
    return {
      MAP_STATUS_PURCHASE_REQUESTS,
      moment,
      breadcrumb: [
        {
          text: "Proyectos",
          disabled: false,
          href: "/",
        },
        {
          text: "Solicitudes de compra",
          disabled: true,
          href: "/supplying/purchase-requests",
        },
      ],
      filters: {
        filter: "",
        page: 1,
        status: null,
      },
      currentProject: null,
      dialogTraceabilityRequest: false,
      dialogMaterialsPurchaseRequest: false,
      loading: false,
      loadingPurchaseRequest: false,
      debounceRequests: false,
      debounceRequestsTime: 500,
      currentPurchaseRequest: false,
      tableHeaders: [
        {
          text: "Acción",
          align: "center",
          value: "actions",
          className: "px-0",
          width: "3%",
          sortable: false,
        },
        {
          text: "Proyecto/CECO",
          align: "start",
          sortable: false,
          value: "code",
          width: "3%",
        },
        {
          text: "Etapa proyecto",
          align: "start",
          sortable: false,
          value: "stage_code",
          width: "3%",
        },
        {
          text: "Código SAP",
          align: "start",
          sortable: false,
          value: "sap_code",
          width: "5%",
        },
        {
          text: "Tipo de solicitud",
          align: "center",
          sortable: false,
          value: "request_type.name",
          width: "10%",
        },
        {
          text: "Estatus",
          align: "center",
          sortable: false,
          value: "status",
          width: "5%",
        },
        {
          text: "Fecha de creación",
          align: "center",
          sortable: false,
          value: "created_at",
          width: "5%",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      getProjects: "requestMaterialsSap/getProjects",
      getPurchaseRequests: "supplying/getPurchaseRequests",
      sendUpdateStatusPurchaseRequest: "supplying/updateStatusPurchaseRequest",
    }),
    async getPurchaseRequestsData(page = 1) {
      clearTimeout(this.debounceRequests);
      this.debounceRequests = setTimeout(async () => {
        this.loadingPurchaseRequest = true;
        await this.getPurchaseRequests({
          ...this.filters,
          page: page,
          filter: this.filters.filter ? this.filters.filter : undefined,
          project_filter: this.currentProject
            ? this.currentProject.split(" - ")[0]
            : undefined,
          status_filter: this.filters.status
            ? MAP_STATUS_PURCHASE_REQUESTS[this.filters.status].status
            : undefined,
        });
        this.loadingPurchaseRequest = false;
      }, this.debounceRequestsTime);
    },
    updateCurrentProject(project_code) {
      this.currentProject = project_code;
    },
    cleanCurrentProject() {
      this.currentProject = null;
    },
    openModalTraceabilityRequests(purchaseRequest) {
      this.currentPurchaseRequest = purchaseRequest;
      this.dialogTraceabilityRequest = true;
    },
    closeModalTraceabilityRequests() {
      this.currentPurchaseRequest = null;
      this.dialogTraceabilityRequest = false;
    },
    openModalMaterialsPurchaseRequest(purchaseRequest) {
      this.currentPurchaseRequest = purchaseRequest;
      this.dialogMaterialsPurchaseRequest = true;
    },
    closeModalMaterialsPurchaseRequest() {
      this.currentPurchaseRequest = null;
      this.dialogMaterialsPurchaseRequest = false;
    },
    getMappedStatusPurchaseRequest(status) {
      return MAP_STATUS_PURCHASE_REQUESTS[status]
        ? MAP_STATUS_PURCHASE_REQUESTS[status]
        : { name: status, color: "grey darken-3" };
    },
    getColorFromStatusPlataform(status) {
      switch (true) {
        case status.includes("Parcialmente"):
          return "orange darken-1";
        case status.includes("Comprado"):
          return "purple darken-1";
        case status.includes("Recibido"):
          return "green darken-1";
        case status.includes("Cancelado"):
          return "red darken-1";
        default:
          return "primary";
          break;
      }
    },
    async updateStatusPurchaseRequest(newStatus, request) {
      let self = this;
      self.loading = true;
      let payload = {
        id: request.id,
        status: MAP_STATUS_PURCHASE_REQUESTS[newStatus].value,
        status_text: newStatus,
        user: self.user.email,
      };
      const resp = await self.sendUpdateStatusPurchaseRequest(payload);
      if (!resp.status || resp.status === 400) {
        self.loading = false;
        return swal2.fire({
          icon: "error",
          title: "Error",
          text: resp.data.detail,
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 10000,
          timerProgressBar: true,
        });
      }
      if (resp.status == 200) {
        swal2.fire({
          icon: "success",
          title: "Excelente",
          text: "Estatus de solicitud actualizado correctamente",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 5000,
          timerProgressBar: true,
        });
      }
      this.loading = false;
    },
    closeCurrentEditDialog(item) {
      this.$refs[`edit-dialog-${item.sap_code}`].isActive = false;
    },
    openCurrentEditDialog(item) {
      this.$refs[`edit-dialog-${item.sap_code}`].isActive = true;
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      projects: "requestMaterialsSap/projects",
      purchaseRequests: "supplying/purchaseRequests",
    }),
    wrappedPurchaseRequests() {
      return this.purchaseRequests.items;
    },
  },
  watch: {
    "filters.page": function (page) {
      this.getPurchaseRequestsData(page);
    },
  },
};
</script>

<style></style>
