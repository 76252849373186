var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-dialog',{attrs:{"max-width":"1400px","scrollable":""},on:{"keydown":_vm.executeCloseModalMaterialsPurchaseRequest,"click:outside":_vm.executeCloseModalMaterialsPurchaseRequest},model:{value:(_vm.modalAction),callback:function ($$v) {_vm.modalAction=$$v},expression:"modalAction"}},[(_vm.currentPurchaseRequest)?_c('v-card',[_c('div',{staticClass:"p-4 pb-0"},[_c('h5',[_vm._v(" COD Solicitud: "),_c('b',[_vm._v(" "+_vm._s(_vm.currentPurchaseRequest.sap_code)+" ")]),_vm._v(" | Tipo de solicitud: "),_c('b',[_vm._v(" "+_vm._s(_vm.currentPurchaseRequest.request_type.name)+" ")])])]),_c('hr'),_c('v-card-text',[_c('v-container',{staticClass:"pt-0 px-0"},[_c('div',{staticClass:"d-flex justify-content-between align-items-end mb-5"},[_c('section',{staticClass:"w-50"},[_c('v-text-field',{staticClass:"px-2",attrs:{"clearable":"","placeholder":"Buscar:","hide-details":""},model:{value:(_vm.filters.filter),callback:function ($$v) {_vm.$set(_vm.filters, "filter", $$v)},expression:"filters.filter"}})],1)]),_c('v-data-table',{staticClass:"px-2 elevation-4 pt-2 ",attrs:{"headers":_vm.tableHeaders,"items":_vm.filteredMaterials,"items-per-page":200,"hide-default-footer":"","loading-text":"Cargando materiales","item-key":"id","no-data-text":"Sin materiales"},on:{"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('v-chip',{staticClass:"mt-0 text-white",attrs:{"label":"","small":"","color":"primary"}},[_vm._v(" "+_vm._s(item.material.code)+" ")])],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-left my-0",staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(item.material.name)+" ")])]}},{key:"item.unit",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-center my-0",staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(item.material.measurement_unit)+" ")])]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-right mt-4",staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(item.quantity)+" ")])]}},{key:"item.purchased_quantity",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-right mt-4",staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(item.purchased_quantity)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{ref:("edit-dialog-" + (item.material.code))},[_c('div',{staticClass:"d-flex align-items-center"},[_c('v-chip',{staticClass:"text-white",attrs:{"label":"","small":"","color":_vm.getMappedStatusPurchaseRequest(
                        item.status_manual ? item.status_manual : item.status
                      ).color}},[_vm._v(" "+_vm._s(_vm.getMappedStatusPurchaseRequest( item.status_manual ? item.status_manual : item.status ).name)+" ")])],1)])]}},{key:"item.actions",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"disabled":!item.purchase_order_items.length,"icon":"","color":"primary","x-small":""},on:{"click":function($event){return _vm.openModalPurchaseOrders(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-clipboard-text ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver Ordenes de Compra asociadas")])])],1)]}}],null,false,2437039761)})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","outlined":""},on:{"click":_vm.executeCloseModalMaterialsPurchaseRequest}},[_c('small',[_vm._v(" Cerrar ")])])],1)],1):_vm._e()],1),_c('modal-eta-materials',{attrs:{"modalAction":_vm.dialogEtaMaterials,"closeModalEtaMaterials":_vm.closeModalEtaMaterials,"materialsToDefineEta":_vm.materialsSelected},on:{"etaMaterialsUpdated":_vm.updateEtaMaterials}}),_c('modal-purchase-orders',{attrs:{"modalAction":_vm.dialogPurchaseOrders,"closeModalPurchaseOrders":_vm.closeModalPurchaseOrders,"currentMaterial":_vm.currentMaterial}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }